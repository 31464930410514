import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'
import { Bath, MapPin } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import Compare from '../../compare'
import { useCardModel } from '../card.model'
import { Property } from '@/@types/global'
import { memo } from 'react'

type Props = {
  data: Property
  compared?: boolean
  size?: 'small' | 'default'
}

const CardGridView = memo(({ data, compared, size = 'default', ...props }: Props) => {
  const isSmall = size === 'small'
  const imgs = data?.attachments.map((img, index) => {
    if (index < 3)
      return (
        <CarouselItem className={` w-full ${isSmall ? 'h-[150px] md:h-[250px]' : 'h-[250px]'}`}>
          <img
            src={img}
            alt={`Imagem da propriedade ${index + 1} do usuário ${data.customer?.name}`}
            className={`!w-full ${isSmall ? 'h-[150px] md:h-[250px]' : 'h-[250px]'} size-full rounded-t-lg`}
          />
        </CarouselItem>
      )
  })

  return (
    <Card className={`flex flex-col  w-full bg-white ${isSmall ? 'p-2' : 'p-4'}`} {...props}>
      <div className="relative">
        {imgs.length > 1 && (
          <Carousel className={`relative flex w-full ${isSmall ? 'h-[150px] md:h-[250px]' : 'h-[250px]'}`}>
            <CarouselContent>{imgs}</CarouselContent>
            <CarouselPrevious className="left-0 bg-white" />
            <CarouselNext className="right-0 bg-white" />
          </Carousel>
        )}
        {imgs.length === 1 && (
          <img
            className={`!w-full ${isSmall ? 'h-[150px] md:h-[250px]' : 'h-[250px]'} size-full rounded-t-lg`}
            src={data.attachments[0]}
            alt={`Imagem da propriedade 01 do usuário ${data.customer?.name}`}
          />
        )}
        {imgs.length === 0 && (
          <Image
            src={'/Item_sem_imagem.svg'}
            alt={`Sem imagem da propriedade do usuário ${data.customer?.name}`}
            lazyBoundary=""
            quality={10}
            width={0}
            height={0}
            layout="responsive"
            className={`!w-full ${isSmall ? '!h-[150px] md:!h-[250px]' : '!h-[250px]'} object-contain rounded-t-lg`}
          />
        )}
        {compared && <Compare data={data} />}
      </div>

      <Link href={`/property/${data.slug}`} className={isSmall ? 'p-2' : 'p-4'}>
        <CardHeader className="p-0">
          <CardTitle className={`truncate ${isSmall ? 'text-sm md:text-lg' : 'text-lg'} p-0`}>{data?.title}</CardTitle>
          <CardDescription className="flex items-center p-0 w-full gap-2">
            <MapPin size={isSmall ? 16 : 20} className="w-6 text-gray-500" />
            <p className="truncate text-sm text-[#7B818F]">{data?.location.fullAddress}</p>
          </CardDescription>
        </CardHeader>
        
        <h4 className={`text-sm uppercase text-[#3C4453] ${isSmall ? 'mt-1 md:mt-2' : 'mt-2'}`}>Detalhes:</h4>
        
        <div className="flex items-center justify-between gap-1">
          <CardDescription className="flex gap-2">
            <Image src={'/bad.svg'} width={20} height={20} alt="icone de cama" />
            <p className="text-x text-[#7B818F] truncate">{data?.details?.bathrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Bath size={20} className="text-[#7B818F]" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.bathrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src={'/subway.svg'} width={20} height={20} alt="icone de cama" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.areaTotal} m²</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src={'/garage.svg'} width={20} height={20} alt="icone de cama" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.areaTotal}</p>
          </CardDescription>
        </div>
      </Link>
      
      <CardFooter className="w-full flex flex-1 flex-col p-2">
        <div className="flex items-center w-full overflow-hidden justify-between gap-2">
          <p className="truncate">
            {data.price === 0 ? (
              <p className="text-[12px]">Sob consulta</p>
            ) : (
              data.price?.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })
            )}
          </p>
          {data.customer?.id && (
            <Link href={`/agency/${data.customer?.id}`} className="flex items-center gap-3 hover:underline justify-end">
              <p className={`truncate ${isSmall ? 'text-xs md:text-sm' : 'text-sm'} max-w-[80%] `}>{data.customer.name}</p>
              <Avatar className={isSmall ? 'w-[20px] h-[20px] md:w-[30px] md:h-[30px]' : 'w-[30px] h-[30px] '}>
                <AvatarImage src={data.customer?.avatar} alt={data.customer?.name} />
                <AvatarFallback>{data.customer?.name[0]}</AvatarFallback>
              </Avatar>
            </Link>
          )}
        </div>
      </CardFooter>
    </Card>
  )
})

export default CardGridView
